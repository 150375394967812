import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AndroidService } from '@/service/Android';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export async function getJsonFromUrl(url) {
  try {
    const json = await Axios.get(url, {
      headers: { 'content-type': 'application/json' },
    });
    return json.data;
  } catch (err) {
    console.log(err);
  }
}

export const isWebView = () => {
  const navigator = window?.navigator;
  return navigator.userAgent.includes('wv');
};

export function hexToRgb(hexCode) {
  // Remove '#' if it's included
  hexCode = hexCode.replace('#', '');

  // Parse hex code into RGB values
  let r = parseInt(hexCode.substring(0, 2), 16);
  let g = parseInt(hexCode.substring(2, 4), 16);
  let b = parseInt(hexCode.substring(4, 6), 16);

  // Return object with RGB values
  return { r, g, b };
}

// export function pickTextColorBasedOnBgColor(
//   bgColor,
//   lightColor = '#FFFFFF',
//   darkColor = '#000000'
// ) {
//   var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
//   var r = parseInt(color.substring(0, 2), 16); // hexToR
//   var g = parseInt(color.substring(2, 4), 16); // hexToG
//   var b = parseInt(color.substring(4, 6), 16); // hexToB
//   var uicolors = [r / 255, g / 255, b / 255];
//   var c = uicolors.map(col => {
//     if (col <= 0.03928) {
//       return col / 12.92;
//     }
//     return Math.pow((col + 0.055) / 1.055, 2.4);
//   });
//   var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
//   return L > 0.179 ? darkColor : lightColor;
// }

// export function formatSmartNumber(num, config = {}) {
//   let {
//     minNumLength = 2,
//     roundUpToLargeUnit = true,
//     allowDecimalPoint = true,
//     fractionRounding = true, //deprecated
//   } = config;
//   const opt = { num: undefined, unit: undefined };
//   opt.toString = smartNumberToString.bind(opt);

//   num = +num;
//   if (isNaN(num)) {
//     opt.num = num;
//     opt.unit = '';
//     return opt;
//   }
//   let largeUnit = '';

//   if (roundUpToLargeUnit && num > 999) {
//     num =
//       num > 999999999
//         ? ((largeUnit = 'B'), num / 1000000000)
//         : num > 999999
//         ? ((largeUnit = 'Mn'), num / 1000000)
//         : ((largeUnit = 'K'), num / 1000);
//   }

//   // Check which is max.
//   const intNum = parseInt(num, 10);
//   minNumLength = Math.max(minNumLength, intNum.toString().length);

//   const fractionPart = num - intNum;

//   if (fractionPart && intNum === 0) {
//     //edge case for fractional value (eg. 0.00000323 but minNumLength  is 2)
//     opt.num = num.toFixed(minNumLength - 1);
//   } else if (!fractionRounding) {
//     const numStr = num.toString(10);
//     opt.num = toFixedNoRoundUp(
//       numStr,
//       Math.max(minNumLength - intNum.toString(10).length, -1)
//     );
//   } else {
//     opt.num = num.toPrecision(minNumLength);
//   }

//   opt.unit = largeUnit;

//   if (!opt.unit && !allowDecimalPoint) {
//     //if it should be integer and no rounding up happended,

//     opt.num = String(parseInt(opt.num));
//   }

//   return opt;
// }

// export const smartFormator = {
//   formatPercentage(num, { minNumLength = 3 } = {}) {
//     return (
//       formatSmartNumber(num, {
//         allowDecimalPoint: true,
//         // fractionRounding: 4,
//         minNumLength,
//         roundUpToLargeUnit: false,
//       }).toString() + '%'
//     );
//   },

//   formatInt(num) {
//     // if (num < 100000) return num;
//     return formatSmartNumber(num, {
//       allowDecimalPoint: false,
//       minNumLength: 3,
//       roundUpToLargeUnit: true,
//     }).toString();
//   },

//   formatSmallInt(num) {
//     return formatSmartNumber(num, {
//       allowDecimalPoint: true,
//     }).toString();
//   },

//   formatIntNoRounding(num) {
//     return formatSmartNumber(num, {
//       allowDecimalPoint: false,
//       minNumLength: 3,
//       roundUpToLargeUnit: false,
//     }).toString();
//   },

//   formatCurrency(num, { roundUpToLargeUnit = false, minNumLength = 3 } = {}) {
//     return formatNegativeCurrencyValue(
//       '$' +
//         formatSmartNumber(num, {
//           allowDecimalPoint: true,
//           minNumLength,
//           roundUpToLargeUnit,
//         }).toString()
//     );
//   },
//   formatCurrencyRounding(
//     num,
//     { roundUpToLargeUnit = true, minNumLength = 3 } = {}
//   ) {
//     return formatNegativeCurrencyValue(
//       '$' +
//         formatSmartNumber(num, {
//           allowDecimalPoint: true,
//           minNumLength,
//           roundUpToLargeUnit,
//         }).toString()
//     );
//   },
//   formatIndianCurrency(num) {
//     return formatNegativeCurrencyValue(
//       '₹' +
//         formatSmartNumber(num, {
//           allowDecimalPoint: true,
//           minNumLength: 3,
//           roundUpToLargeUnit: false,
//         }).toString()
//     );
//   },
//   formatIndianCurrencyRounding(num) {
//     return formatNegativeCurrencyValue(
//       '₹' +
//         formatSmartNumber(num, {
//           allowDecimalPoint: true,
//           minNumLength: 3,
//           roundUpToLargeUnit: true,
//         }).toString()
//     );
//   },
// };

// function checkIfDecimalIsZero(num) {
//   // Using toFixed(2) to format the number with two decimal places
//   const formattedNumber = num.toFixed(2);

//   // Extracting the decimal part as a string
//   const decimalPart = formattedNumber.split('.')[1];

//   // Checking if the decimal part is "00"
//   return decimalPart === '00';
// }
// export function hasDecimalPlaces(num) {
//   return num % 1 !== 0;
// }
// export function payoutNumberFormator(d) {
//   return simplifyNumber(d, {
//     breakAt: 10000,
//     decimalPlaces: d > 10000 ? 1 : hasDecimalPlaces(d) ? 1 : 0,
//   });
// }

// export function simplifyNumber(number, { decimalPlaces = 2, breakAt = 1000 }) {
//   if (number < breakAt) {
//     return hasDecimalPlaces(number) ? number.toFixed(decimalPlaces) : number;
//   }

//   const suffixes = ['', 'k', 'Mn', 'B', 'T'];
//   const suffixIndex = Math.floor(Math.log10(number) / 3);
//   const simplifiedNumber = number / Math.pow(1000, suffixIndex);

//   if (suffixIndex >= suffixes.length) {
//     return number.toFixed(decimalPlaces); // Number is too large to simplify
//   }

//   const roundedNumber =
//     Math.round(simplifiedNumber * Math.pow(10, decimalPlaces)) /
//     Math.pow(10, decimalPlaces);
//   const suffix = suffixes[suffixIndex];
//   console.log('roundedNumber', roundedNumber);
//   return roundedNumber + suffix;
// }

// function smartNumberToString({ format = true, excludeUnit = false } = {}) {
//   let str = String(this.num);
//   if (format) {
//     str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }

//   if (!excludeUnit) {
//     str = `${str}${this.unit}`;
//   }

//   return str;
// }

export function formatDecimal(num) {
  if (typeof num !== 'number') return num;
  if (num % 1 !== 0) {
    return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  } else {
    return parseFloat(num).toFixed(0);
  }
}

export function formatNumber(num, threshold = 1e3) {
  // Define the thresholds for K, M, B, T, etc.
  const thresholds = [
    [1e12, 'T'],
    [1e9, 'B'],
    [1e6, 'M'],
    [1e3, 'K'],
  ];

  // Run the loop only when the threshold value is less than the provided threshold
  if (num > threshold) {
    for (const [thresholdValue, suffix] of thresholds) {
      if (num >= thresholdValue) {
        const formattedNum = num / thresholdValue;
        // If the number is a decimal, truncate decimal places to 2 digits without rounding
        const formattedString = formatDecimal(formattedNum);
        return formattedString + suffix;
      }
    }
  }

  // If the number is below the specified threshold or above the highest threshold, return it as is
  return formatDecimal(num);
}

export const getOfferType = offerType => {
  switch (offerType) {
    case 'CPI':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'CPI'
      ];
    case 'CPA':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'CPA'
      ];
    case 'CPR':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'CPR'
      ];
    case 'APK-INSTALL':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'APK-INSTALL'
      ];
    case 'APK_INSTALL':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'APK-INSTALL'
      ];
    case 'APK-REGISTER':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'APK-REGISTER'
      ];
    case 'APK_REGISTER':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'APK-REGISTER'
      ];
    case 'CPL':
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'CPL'
      ];
    default:
      return LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].type[
        'default'
      ];
  }
};

export const truncateString = (string, length) => {
  if (!string) return;
  return string.substring(0, length) + '...';
};

export function toast(message) {
  if (!message) return;
  AndroidService.showToast(message);
}

export function getSecondsRemaining(timestamp) {
  const now = new Date().getTime();
  const distance = new Date(timestamp) - now;
  return Math.floor(distance / 1000);
}

export function toTimestamp(date) {
  var datum = Date.parse(date);
  return datum / 1000;
}

export function encodeUrlParam(param) {
  return encodeURIComponent(JSON.stringify(param));
}
export function decodeUrlParam(param) {
  return JSON.parse(decodeURIComponent(param));
}

export function truncate(string, length) {
  if (!string) return;
  return string.substring(0, length || 20) + '...';
}
export function paisaToRupee(paisa) {
  if (!paisa) return 0;
  return Number(paisa / 100).toFixed(2);
}

export const calculatePercentage = (current, base) => {
  const value = (Number(current) / Number(base)) * 100;
  if (isNaN(value)) {
    return 0;
  } else if (value === Infinity) {
    return 0;
  } else {
    return +Number(value).toFixed(2);
  }
};

export function formattedTime(seconds) {
  var helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, 'mm:ss');
}

export const secondsToMinutes = seconds =>
  Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);

export async function copyToClickBoard(str) {
  str = String(str);
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(str);
      return true;
    } else {
      const input = document.createElement('input');
      input.style = 'position: absolute; z-index: 100000000;  top: 0';
      input.value = str;
      document.body.append(input);
      input.focus();
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      return true;
    }
  } catch (error) {
    console.log('Error', error);
    return false;
  }
}

export function secondsToHms(d) {
  d = Number(d);
  var hour = Math.floor(d / 3600);
  var minutes = Math.floor((d % 3600) / 60);
  var seconds = Math.floor((d % 3600) % 60);

  return {
    hour,
    minutes,
    seconds,
  };
}

export async function downloadApk(fileName = 'SikkaPRO.apk') {
  try {
    await downloadLink(
      process.env.NEXT_PUBLIC_SIKKA_APK_DOWNLOAD_URL,
      fileName
    );
  } catch (error) {
    console.log('error', error);
  }
}
function downloadLink(url, filename = 'SikkaPRO.apk') {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
}

export const formatUTC = date => {
  const parsedTime = parseISO(date);
  // todo check out the api date format

  return parsedTime;
};

export const convertToUnixUTC = unix => {
  const d = new Date(unix);
  d.setHours(d.getHours() + 5);
  d.setMinutes(d.getMinutes() + 30);
  return getUnixTime(d) * 1000;
};

export function formatStringForURL(str) {
  if (!str) return '';
  // replace all non alphanumeric characters with hyphens, / with -, ( with hyphens, ) with hyphens and make it lowercase
  return (
    str
      .replace(/\//g, ' ')
      .replace(/\(/g, ' ')
      .replace(/\)/g, ' ')
      .replace(/\:/g, ' ')
      // replace quiestion mark with hyphen
      .replace(/\?/g, ' ')
      .replace(/\&/g, '')
      .replace(/\./g, '-')
      .trim()
      .replace(/ /g, '-')
      // remove any double hyphens
      .replace(/--/g, '-')
      .toLowerCase()
  );
}

export const currencyMap = {
  INR: '₹',
  USD: '$',
};
// make a currency map and add a fallback
export const getCurrencySymbol = currency => {
  return currencyMap?.[currency] || currency;
};

export function isValidEmail(email) {
  if (!email) return false;
  const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function isValidRegex(regex, value) {
  let regexStr = decodeURIComponent(regex);
  if (!value) return false;
  return new RegExp(regexStr).test(value);
}

export function generateUniqueKey() {
  return Date.now() + Math.random().toString(36).substr(2, 9);
}
export function snakeCaseToPascaleCaseWithSpace(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function isImageLoaded(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => reject(false);
    img.src = src;
  });
}
export function trimSpacesFromString(str) {
  if (!str) return '';
  let isMultiline = str.includes('\n');
  if (isMultiline) {
    return str
      .split('\n')
      .map(d => d?.trim())
      .join('\n');
  }
  return str.trim();
}

export function getStartAndEndTimestamps() {
  // Get the current date
  var currentDate = new Date();

  // Set the time to the beginning of the day (00:00:00)
  currentDate.setHours(0, 0, 0, 0);

  // Get the timestamp for the start of the day
  var startTimestamp = currentDate.getTime();

  // Set the time to the end of the day (23:59:59)
  currentDate.setHours(23, 59, 59, 999);

  // Get the timestamp for the end of the day
  var endTimestamp = currentDate.getTime();

  return [startTimestamp, endTimestamp];
}

export function makeMap(arr, keyFinder, formator) {
  return arr.reduce((obj, c, idx, arr) => {
    obj[keyFinder(c, idx, arr)] = formator ? formator(c) : c;
    return obj;
  }, {});
}

export function convertUnixTimeStamp(unixTimeStamp) {
  // Calculate the total seconds, minutes, hours, and days
  const totalSeconds = unixTimeStamp % 60;
  const totalMinutes = Math.floor(unixTimeStamp / 60) % 60;
  const totalHours = Math.floor(unixTimeStamp / 3600) % 24;
  const totalDays = Math.floor(unixTimeStamp / (3600 * 24));

  // Format the results as strings
  const days = totalDays;
  const hours = totalHours.toString().padStart(2, '0');
  const minutes = totalMinutes.toString().padStart(2, '0');
  const seconds = totalSeconds.toString().padStart(2, '0');

  return fomatTime({ days, hours, minutes, seconds });
}

function fomatTime({ days, hours, minutes, seconds }) {
  const OneDayHoursText =
    hours === '00'
      ? ''
      : hours === '01'
      ? ` and ${hours} hour`
      : ` and ${hours} hours`;
  return days
    ? days === 1
      ? `${days} day` + OneDayHoursText
      : `${days} days`
    : `${hours}:${minutes}:${seconds}`;
}

export function secondsToReadableTime(time) {
  let t = time; // Time is already in seconds

  if (t < 60) {
    return `${Math.round(t)} sec`;
  }

  let minutes = Math.round(t / 60);
  if (minutes < 60) {
    return `${minutes} mins`;
  }

  let hours = Math.round(minutes / 60);
  if (hours < 24) {
    return `${hours} hrs`;
  }

  let days = Math.round(hours / 24);
  return `${days} days`;
}

export async function loadScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function checkIfDecimalIsZero(num) {
  // Using toFixed(2) to format the number with two decimal places
  const formattedNumber = num.toFixed(2);

  // Extracting the decimal part as a string
  const decimalPart = formattedNumber.split('.')[1];

  // Checking if the decimal part is "00"
  return decimalPart === '00';
}

export function simplifyNumber(number, breakAt = 1000) {
  number = Math.round(number * 100) / 100; // Round to two decimal places to avoid floating-point precision issues

  if (number < breakAt) {
    const res = number.toFixed(2);
    if (checkIfDecimalIsZero(number)) {
      return number;
    }
    return res;
  }

  const suffixes = ['', 'k', 'Mn', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(number) / 3);
  const simplifiedNumber = number / Math.pow(1000, suffixIndex);

  if (suffixIndex >= suffixes.length) {
    return number.toFixed(2); // Number is too large to simplify
  }

  const roundedNumber = Math.round(simplifiedNumber * 10) / 10;
  const suffix = suffixes[suffixIndex];

  return roundedNumber + suffix;
}